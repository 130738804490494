import getConfig from "next/config";

const { publicRuntimeConfig } = getConfig();

export const GTM_ID = publicRuntimeConfig.NEXT_PUBLIC_GOOGLE_TAG_MANAGER_ID;
export const GTM_ENV_URL =
  publicRuntimeConfig.NEXT_PUBLIC_GOOGLE_TAG_MANAGER_FULL_URL_WITH_ENVIRONMENT;

export const pageview = (url) => {
  window.dataLayer.push({
    event: "pageview",
    page: url,
  });
};

export const googleEvent = ({ eventName, action, category, label, value }) => {
  window.dataLayer.push({
    event: eventName,
    action: action,
    category: category,
    label: label,
    value: value,
  });
};

export const conversionSubmitLeadForm = ({ }) => {
  window.dataLayer.push({
    event: "conversion_event_submit_lead_form",
    action: "click",
    category: "button",
    label: "signup-banner",
    value: "1",
  });
};

export const mobileConversionDownloadButton = () => {
  window.dataLayer.push({
    event: "conversion_event_signup",
    action: "click",
    category: "button",
    label: "download-button",
    value: "1",
    eventTimeout: 2000,
  });
};

export const mobileConversionStore = ({ label }) => {
  window.dataLayer.push({
    event: "conversion_event_signup",
    action: "click",
    category: "button",
    label: label,
    value: "1",
    eventTimeout: 2000,
  });
};

