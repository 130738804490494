import { useRouter } from "next/router";
import { useEffect, useRef } from "react";

const usePreserveQueryParams = () => {
  const router = useRouter();
  const preservedQueryParams = useRef<{ string: string } | {}>({});
  const previousPathname = useRef(router.pathname);

    useEffect(() => {
        preservedQueryParams.current = { ...router.query };
      }, []);
  

  useEffect(() => {
    const handleRouteChangeStart = (url: string) => {
      const [path, queryString] = url.split("?");
      const newParams = new URLSearchParams(queryString || "");
      let hasChanged = false;

      Object.entries(preservedQueryParams.current).forEach(([key, value]) => {
        if (!newParams.has(key)) {
          newParams.append(key, value);
          hasChanged = true;
        }
      });

      if (hasChanged) {
        const newUrl = `${path}?${newParams.toString()}`;
        router.replace(newUrl, undefined, { shallow: true });
      }
    };

    if (previousPathname.current !== router.pathname) {
      previousPathname.current = router.pathname;
      handleRouteChangeStart(window.location.href);
    }
  }, [router.pathname, router.query]);
};

export default usePreserveQueryParams;
